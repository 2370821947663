import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import App from './app';
import reportWebVitals from './reportWebVitals';
import Provider from 'context/Provider';
import DataProvider from 'context/DataProvider';
import 'styles/global.css';
import 'moment/locale/id';
import * as Sentry from '@sentry/react';
import sentryJSON from './sentry-release';
moment.locale('id');

if (window.REACT_APP_SENTRY_ENV !== '') {
  Sentry.init({
    dsn: window.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: window.REACT_APP_SENTRY_SAMPLE_RATE,
    release: sentryJSON.release,
    environment: window.REACT_APP_SENTRY_ENV,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider>
        <DataProvider>
          <App />
        </DataProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { MSL_API_URL } from 'environment';

const SEGMENT_TYPE_URL = `${MSL_API_URL}/admin/master-data/produk/segment`;

export async function getAllSegmentTypeAPI(token, query) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${SEGMENT_TYPE_URL}/all${query && query}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function getSegmentTypesAPI(token, query) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${SEGMENT_TYPE_URL}${query && query}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function addSegmentAPI(token, product_type_id, name, adira_id) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({
    name,
    product_type_id,
    adira_id,
  });

  const requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${SEGMENT_TYPE_URL}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function updateSegmentTypeAPI(token, id, product_type_id, name, adira_id) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({
    name,
    product_type_id,
    adira_id,
  });

  const requestOptions = { method: 'PUT', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${SEGMENT_TYPE_URL}/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function removeSegmentAPI(token, id) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'DELETE', headers: myHeaders, redirect: 'follow' };

  return fetch(`${SEGMENT_TYPE_URL}/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

import { useCallback, useState } from 'react';

export default function sellerReportState() {
  const [dataFromList, setDataFromList] = useState({});
  const [dataCurrentSeller, setDataCurrentSeller] = useState({});

  const setSellerData = (data) => {
    setDataFromList(data);
  };

  const setCurrentSeller = (data) => {
    setDataCurrentSeller(data);
  };

  return {
    dataFromList,
    setSellerData,
    dataCurrentSeller,
    setCurrentSeller,
  };
}

export const initiateSellerReportState = {
  dataFromList: [],
  dataCurrentSeller: {},
  setSellerData: () => alert('setSellerData: unhandled function'),
  setCurrentSeller: () => alert('setCurrentSeller: unhandled function'),
};

import { createContext, useContext, useReducer } from 'react';

const DataContext = createContext(null);
const DataDispatch = createContext(null);

export default function DataProvider({ children }) {
  const [data, dispatch] = useReducer(dataReducer, initialDataState);

  return (
    <DataContext.Provider value={data}>
      <DataDispatch.Provider value={dispatch}>{children}</DataDispatch.Provider>
    </DataContext.Provider>
  );
}

export function useData() {
  return useContext(DataContext);
}

export function useDataDispatch() {
  return useContext(DataDispatch);
}

function dataReducer(data, action) {
  switch (action.type) {
    case 'sellerReport': {
      return [
        ...data,
        {
          action,
        },
      ];
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

const initialDataState = {
  sellerList: {},
};
